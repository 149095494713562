import React, { useState } from 'react';
import './controls.css'

export const Popover = ({ children, onRenderPopup, text }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className='clickable' onMouseEnter={() => { setVisible(true) }} onMouseOut={() => { setVisible(false) }}>
            {children}
            {visible &&
                <div className='popover' onMouseEnter={() => { setVisible(true) }}>
                    <div className='triangle' />
                    <div className='popover-content'>
                    {onRenderPopup
                        ? <div>{onRenderPopup()}</div>
                        : <div>{text}</div>
                    }
                    </div>
                </div>                
            }
        </div>
    )

}
