import React, { useState } from 'react';
import { Copy } from 'react-feather';

export const ToggleContainer = props => {

    const [visible, setVisible] = useState(null);

    return (
        <div className={visible ? 'toggleContainer' : 'toggleContainer'}>
            {visible
                ? <div>{props.children}</div>
                : <div className='toggle'><span onClick={() => { setVisible(true) }}>{props.title}</span></div>
            }
        </div>
    )
}


export const CodeBlock = props => {

    const copyToClipboard = () => {
        var text = props.onCopyToClipboard();
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Copied Data to Clipboard');
        }, err => {
            console.error(err);
        });
    }

    return (
        <div className='codeblock'>

            {props.onCopyToClipboard &&
                <div className='toolbar'>
                    <Copy className='icon pull-right' title='Copy to Clipboard' onClick={() => { copyToClipboard(); }} />
                </div>
            }
            <pre>
                {props.children}
            </pre>
        </div>
    )

}