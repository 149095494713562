import React from 'react';
import { Capability } from '../infrastructure/Capabilities';

export class LoaderComponent extends Capability {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    show() {
        this.setState({
            visible: true
        })
    }

    hide() {
        this.setState({
            visible: false
        })
    }

    render() {
        return (
            <div>
                {this.state.visible &&
                    <div>
                        <div className='backSplash' />
                        <div className='loader-screen'>
                            <img alt='loading....' src='/images/ajax-loader.gif' />
                        </div>
                    </div>
                }
            </div>
        )
    }
}