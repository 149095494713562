import React, { useContext, useEffect, useState, useRef } from 'react';
import { Textbox, Textarea } from '../core/Textbox';
import { Toggle } from '../core/Toggle';
import { DropdownList } from '../core/DropdownList';
import { Form } from '../core/Form';
import { List } from '../core/List';
import { Utils, DotNetType, DotNet } from '../core/Utils';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { Trash } from 'react-feather';


export const PaymentProviderListView = ({ history }) => {

    const list = useRef(null);

    useEffect(() => {
    }, []);


    return (
        <div>
            <h1>Payment Providers</h1>
            <List
                ref={list}
                controller='api/provider'
                action='/method'
                headers={[
                    { column: 'providerName', title: 'Provider', sortable: true }
                ]}
                onRenderRow={(column, item) => {
                    switch (column) {
                        case 'providerName':
                            return (<PaymentProviderRow provider={item} list={list} />)
                    }
                }}
                showHeader={false}
                className='provider'
            />
        </div>
    )
}






export const PaymentProviderRow = ({ provider, list }) => {

    const capabilitiesContext = useContext(CapabilitiesContext);
    const modal = capabilitiesContext.getCapability(CapabilityName.Modal);
    const notification = capabilitiesContext.getCapability(CapabilityName.Notification);


    const getMethods = () => {
        let result = [];
        Object.keys(provider).forEach(key => {
            result.push(provider[key]);
        });
        return result;
    }

    const onConfigSelected = (item) => {
        console.log(item);
        modal.show(
            modal => { return (<ConfigPopupContent payload={item} modal={modal} capabilitiesContext={capabilitiesContext} />); },
            modal => {
                return (
                    <div>
                        <button className='button left' onClick={() => { modal.hide(); }}>Cancel</button>
                        <button className='button right' disabled={!modal.isValid()} onClick={() => { modal.getMetaData('onCloseClick')(); }}>Save</button>
                    </div>
                )
            },
            modal => { return (<h2>Server Config</h2>); },
            {
                onHide: modal => { list.current.refresh(); }
            }
        );
    }

    const onMetaSelected = (item) => {
        console.log(item);
        modal.show(
            modal => { return (<MetaDataPopupContent payload={item} modal={modal} capabilitiesContext={capabilitiesContext} paymentProviderId={item.id} paymentMethodId={item.paymentMethodId} />); },
            modal => {
                return (
                    <div>
                        <button className='button left' onClick={() => { modal.hide(); }}>Cancel</button>
                        <button className='button right' disabled={!modal.isValid()} onClick={() => { modal.getMetaData('onCloseClick')(); }}>Save</button>
                    </div>
                )
            },
            modal => { return (<h2>Client Config</h2>); },
            {
                onHide: modal => { list.current.refresh(); }
            }

        );
    }

    const onToggle = async (item, on) => {

        try {
            var dataAccess = new ApiDataAccess('api/provider');

            var response = on
                ? await dataAccess.post(`/${item.id}/method/${item.paymentMethodId}/activate`)
                : await dataAccess.post(`/${item.id}/method/${item.paymentMethodId}/deactivate`);

            if (!response || !response.hasSuccess) {
                throw new Error('Could enable or disable payment method');
            }

            notification.add('info', 'Success', on ? 'Payment Method has been enabled' : 'Payment Method has been disabled');
        }
        catch {
            notification.add('error', 'Error', on ? 'Could not enable Payment Method' : 'Could not disable Payment Method');
        }

        list.current.refresh();
    }

    return (
        <div>
            <h3>{provider[Object.keys(provider)[0]].providerName}</h3>

            <List
                items={getMethods()}
                headers={[
                    { column: 'paymentMethodName', title: 'Method', width: 6, sortable: true },
                    { column: 'configuration', title: 'Server Config', width: 2, sortable: false },
                    { column: 'items', title: 'Client Config', width: 2, sortable: false },
                    { column: 'isEnabled', title: 'Active', width: 2, sortable: false },
                    { column: 'webhookConfig', title: 'Webhook', width: 2, sortable: false }
                ]}
                onRenderRow={(column, item) => {
                    switch (column) {
                        case 'paymentMethodName':
                            return (<span>{item[column]}</span>)
                        case 'configuration':
                            return (<button className='btn btn-sm btn-primary-outline' onClick={() => { onConfigSelected(item) }}>Set</button>)
                        case 'items':
                            return (<button className='btn btn-sm btn-primary-outline' onClick={() => { onMetaSelected(item) }}>Set</button>)
                        case 'isEnabled':
                            return (<span><Toggle on={item.isEnabled} onChange={on => { onToggle(item, on) }} /></span>)
                    }
                }}
                className='method'
            />


        </div>
    )

}




export const ConfigPopupContent = ({ payload, modal, capabilitiesContext }) => {

    const [definitions, setDefinitions] = useState({});
    const [json, setJson] = useState({});
    const [loaded, setLoaded] = useState(false);
    const notification = capabilitiesContext.getCapability(CapabilityName.Notification);

    const init = async () => {
        var definitions = await loadDataDefinition();
        setDefinitions(definitions);

        var json = payload.configuration ? JSON.parse(payload.configuration) : {};
        setJson(json);

        modal.addMetaData('json', json);
        modal.addMetaData('onCloseClick', () => { onCloseClick() });
        modal.setValid(isValid());

        setLoaded(true);
    }

    const loadDataDefinition = async () => {
        var dataAccess = new ApiDataAccess('api/metadata');
        var items = await dataAccess.get(`/provider/${payload.id}/method/${payload.paymentMethodId}/configuration`);
        return items;
    }

    const onCloseClick = async () => {
        try {
            var json = modal.getMetaData('json');
            if (!isValid()) {
                throw new Error('Json is not valid');
            }
            var dataAccess = new ApiDataAccess('api/provider/' + payload.id + '/method/' + payload.paymentMethodId + '/configuration');
            await dataAccess.patch(`/`, json);
            modal.hide();
            notification.add('info', 'Success', 'Configuration has been saved!')
        }
        catch {
            notification.add('error', 'Error', 'Could not save Configuration. Please try again!')
        }
    }

    const onChange = (property, value) => {
        json[property] = value;
        setJson(json);
        modal.addMetaData('json', json);
        modal.setValid(isValid());
    }

    const isValid = () => {
        var result = true;
        Object.keys(definitions).forEach(property => {
            if (!json[property]) {
                result = false;
            }
        });
        return result;
    }

    useEffect(() => {
        init();
    }, []);


    const getPropertyValue = (propertyName) => {
        var result = json[propertyName];

        console.log('JSON', json, 'PROPERTY', propertyName, 'RESULT', result);

        return result ? result : '';
    }


    return (
        <div>
            {loaded &&
                <div>
                    {Object.keys(definitions).map((propertyName, idx) => {
                        return (
                            <div className="row" key={`row${idx}`}>
                                <div className='column w2'>{propertyName}</div>
                                <div className='column w2'><Textbox id={`txt${propertyName}`} value={getPropertyValue(propertyName)} onChange={txt => { onChange(propertyName, txt) }} required={true} onValidating={txt => { return DotNet.IsValueOfType(txt, definitions[propertyName]) }} /></div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}



export const MetaDataPopupContent = ({ payload, modal, capabilitiesContext, paymentProviderId, paymentMethodId }) => {

    const list = useRef(null);
    const notification = capabilitiesContext.getCapability(CapabilityName.Notification);

    const [dataTypes, setDataTypes] = useState(null);
    const [canAddRow, setCanAddRow] = useState(false);
    const [items, setItems] = useState(null);

    const [dataType, setDataType] = useState(null);
    const [propertyName, setPropertyName] = useState(null);

    const loadDataTypes = async () => {
        var dataAccess = new ApiDataAccess('api/metadata');
        var items = await dataAccess.get('/');
        return items;
    }

    const loadExistingMetadata = async () => {
        var dataAccess = new ApiDataAccess('api/provider/');
        try{
            var temp = await dataAccess.get(`${paymentProviderId}`);
        }
        catch(err){
            console.log(err);
        }

        let items = [];

        if (temp &&  temp[paymentMethodId].items) {
            temp[paymentMethodId].items.map((x, y) => {
                items.push({
                    id: x.id,
                    name: x.name,
                    value: x.value
                })
            });
        }

        console.log("loadexisting", items);

        return items;
    }

    const init = async () => {
        var items = await loadDataTypes();
        var existingMetadata = await loadExistingMetadata();
        
        setDataTypes(items);

        var existingMetadata = Utils.Clone(existingMetadata);
        setItems(existingMetadata);
        console.log("!xs", existingMetadata);

        modal.setValid(items && items.length > 0);
        modal.addMetaData('onCloseClick', () => { onCloseClick() });
        modal.addMetaData('items', items);
    }

    const onCloseClick = async () => {
        try {
            var items = modal.getMetaData('items');
            var request = {
                items: items
            };

            var dataAccess = new ApiDataAccess('api/provider/' + payload.id + '/method/' + payload.paymentMethodId + '/metaData');
            await dataAccess.patch(`/`, request);
            modal.hide();
            notification.add('info', 'Success', 'Meta Data has been saved!')
        }
        catch {
            notification.add('error', 'Error', 'Could not save Meta Data. Please try again!')
        }
    }


    const onDeleteClick = (item) => {
        var idx = items.findIndex(e => e.id === item.id);
        items.splice(idx, 1);
        setItems(items);
        list.current.refresh();
        modal.setValid(items && items.length > 0);
        modal.addMetaData('items', items);
    }

    const setAddButtonState = (valid) => {
        setCanAddRow(valid);
    }


    const onAddClick = () => {
        var idx = items.findIndex(e => e.value.toLowerCase() === propertyName.toLowerCase());
        console.log(idx);

        if (idx>=0) {
            items.splice(idx, 1);
        }
        items.push({ name: dataType.name, value: propertyName})
        setItems(items);
        list.current.refresh();
        modal.setValid(items && items.length > 0);
        modal.addMetaData('items', items);
    }


    useEffect(() => {
        init();
    }, []);


    return (
        <div>
            {dataTypes &&
                <Form onValidated={valid => { setAddButtonState(valid) }}>
                    <div className="row">
                        <div className='column col-4'><Textbox id='propertyName' required={true} onChange={item => { setPropertyName(item) }} onValidating={txt => { return Utils.MatchesRegex('[a-zA-Z]+[0-9]*', txt) }} /></div>
                        <div className='column col-4'><DropdownList id='dataType' required={true} items={dataTypes} onChange={item => { setDataType(item) }}/></div>
                        <div className='column col-4'><button className='btn btn-secondary-outline pull-right' disabled={!canAddRow} onClick={() => { onAddClick() }}>Add</button></div>
                    </div>
                </Form>
            }
            {items &&
                <List
                    ref={list}
                    items={items}
                    headers={[
                        { column: 'value', title: 'Name', width: 4, sortable: true },
                        { column: 'name', title: 'Type', width: 4, sortable: true },
                        { column: 'delete', title: '', width: 4, sortable: false }
                    ]}
                    onRenderRow={(column, item) => {
                        switch (column) {
                            case 'value':
                            case 'name':
                                return (<span>{item[column]}</span>)
                            case 'delete':
                                return (<button className='btn btn-sm btn-primary pull-right' onClick={() => { onDeleteClick(item) }}><Trash className='icon' /></button>)
                        }
                    }}
                />
            }
        </div>
        )
}



