import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;
    render() {
        return (
            <div>
                <button className='btn btn-square btn-tertiary-outline' onClick={() => { this.props.history.push('/transactions') }}>Trans actions</button>
                <button className='btn btn-square btn-tertiary-outline' onClick={() => { this.props.history.push('/paymentprovider') }}>Payment Providers</button>
                <button className='btn btn-square btn-tertiary-outline' onClick={() => { this.props.history.push('/paymentmethod') }}>Payment Methods</button>
                <button className='btn btn-square btn-tertiary-outline' onClick={() => { this.props.history.push('/webhookconfig') }}>Webhook Config</button>
                <button className='btn btn-square btn-tertiary-outline' onClick={() => { document.location = '/listdemo' }}>List Demo</button>
                <button className='btn btn-square btn-tertiary-outline' onClick={() => { this.props.history.push('/pendingtasks') }}>Pending Tasks</button>
            </div>
        );
    }
}
