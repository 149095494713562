import React, { useContext, useRef, useState } from 'react';
import { DateTime } from '../core/DateDisplay';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { CapturePaymentWizard } from '../components/CapturePaymentWizard';
import { DollarSign } from 'react-feather';
import { List } from '../core/List';
import { DropdownList } from '../core/DropdownList';
import { Status, StatusDisplay } from '../core/StatusDisplay';
import { JournalAmountDisplay } from '../components/JournalAmountDisplay';

export const TransactionListView = ({ history }) => {

    const list = useRef(null);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const modal = capabilitiesContext.getCapability(CapabilityName.Modal);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    var referenceTypeFilter = null;


    const onShowClick = () => {
        modal.show(
            modal => {
                return (<CapturePaymentWizard onApproved={(result) => {
                    console.log("onApproved", result);
                    modal.hide();
                    if (result.transactionId) {
                        history.push(`/transactions/${result.transactionId}`);
                    }
                    else {
                        notifications.add("info", "Payment Success", "The payment was accepted succesfully and is pending verification from the provider. Account state is '" + result.data.data.accountStateDescription + "'");
                    }
                }} />);
            },
            modal => { return null },
            modal => { return (<h1>Capture Payment</h1>) }
        );
    }

    const renderReferenceTypeFilter = () => {
        var items = [{ id: 'INV', name: 'Invoice' }, { id: 'ORD', name: 'Order' }];
        return <DropdownList preventInitChangeEvent={true} items={items} value={referenceTypeFilter ? referenceTypeFilter.id : null} onChange={item => { referenceTypeFilter = item; list.current.setFilter('referenceTypeId', item ? item.id : null) }} />
    }

    return (
        <div>
            <h1>Payment Transactions</h1>
            <button className='btn btn-primary' onClick={() => { onShowClick() }}><DollarSign className='icon' /> Capture</button>

            <List
                ref={list}
                controller='api/transaction'
                action='/'
                sortColumn='transactionId'
                sortDesc={true}
                headers={[
                    { column: 'transactionId', title: 'Id', sortable: true, filterable: true, className:'col-1 col-sm-1' },
                    { column: 'paymentProvider', title: 'Provider', sortable: true, filterable: true, className: 'col-1 col-sm-1' },
                    { column: 'paymentMethod', title: 'Method', sortable: true, filterable: true, className: 'col-1 col-sm-1' },
                    { column: 'referenceId', title: 'Reference / Order Nr.', sortable: true, filterable: true, className: 'col-1 col-sm-1' },
                    { column: 'referenceTypeId', title: 'Reference Type', sortable: false, filterable: true, className: 'col-1 col-sm-1', onRenderFilter: () => { return renderReferenceTypeFilter() }  },
                    { column: 'plantName', title: 'Plant Name', sortable: true, filterable: true, className: 'col-1 col-sm-1'},
                    { column: 'transactionStateId', title: 'Result', sortable: true, className: 'col-1 col-sm-1' },
                    { column: 'journal', title: 'Amount', sortable: false, className: 'col-1 col-sm-1' },
                    { column: 'email', title: 'Email', filterable: true, sortable: false, className: 'col-3 col-sm-3'},
                    { column: 'createDate', title: 'Date (your local time)', sortable: true, filterable: false, className: 'col-2 col-sm-2' }
                ]}
                onRenderRow={(column, item, header) => {
                    switch (column) {
                        case 'referenceTypeId':
                            return (<span>{item[column] === "INVCE" ? "Invoice" : "Order"}</span>);
                        case 'journal':
                            return (<JournalAmountDisplay journal={item[column]} />);
                        case 'transactionStateId':
                            return (<StatusDisplay status={item[column]} />);
                        case 'createDate':
                            return (<DateTime dateTime={item[column]} />);
                        default:
                            return item[column] != null ? <span>{item[column]}</span> : <span>&nbsp;</span>;
                            return <div></div>
                    }
                }}
                onSelect={(data) => { history.push(`/transactions/${data.transactionId}`) }}
            />

        </div>
    )
}





