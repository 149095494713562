import React from 'react';
import { Capability } from '../infrastructure/Capabilities';
import { XSquare } from 'react-feather';

export class NotificationComponent extends Capability {

    constructor(props) {
        super(props);

        this.state = {
            items: []
        }

        this.idx = 1;
        this.onClose = this.onClose.bind(this);
    }

    add(type, title, content) {
        this.idx++;
        let item = {
            id: this.idx,
            type: type,
            title: title,
            content: content
        };

        var items = this.state.items;
        items.push(item);
        this.setState({
            items: items
        });
    }

    onClose(idx) {
        var items = this.state.items;
        var idx = items.findIndex(e => e.id == idx);
        if (idx >= 0) {
            items.splice(idx, 1);

            this.setState({
                items: items
            });
        }
    }

    render() {
        return (
            <div className='notificationContainer'>
                {this.state.items.map((item, idx) => {
                    return (
                        <Notification key={`notification${idx}`} idx={item.id} type={item.type} title={item.title} content={item.content} onClose={this.onClose} />        
                        )
                })}
            </div>
        )
    }
}


export const Notification = props => {

    const getStyle = () => {
        if (props.type) {
            switch (props.type.toLowerCase()) {
                case 'info':
                    return 'notification info';
                    break;
                case 'warn':
                    return 'notification warn';
                    break;
                case 'error':
                    return 'notification error';
                    break;
                default:
                    return 'notification info';
            }
        }
    }

    const onClick = () => {
        if (props.onClose) {
            props.onClose(props.idx);
        }
    }

    if (props.type && props.type.toLowerCase() == 'info') {
        setTimeout(() => { onClick() }, 3000);
    }

    return (
        <div className={getStyle()}>                        
            <div className='title'>
                <div className='txt'>{props.title}</div>
                <XSquare className='icon btn-close' onClick={() => { onClick(); }} />
            </div>
            <div className='content'>{props.content}</div>
        </div>
    )
}

