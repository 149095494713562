import React, { Component } from 'react';
import { List } from '../core/List'


export class PaymentMethodListView extends Component {
    constructor(props) {
        super(props);      
    }

    render() {
        return (
            <div>
                <h1>Payment Methods</h1>
                <List
                    controller='api/method'
                    action='/'
                    headers={[
                        { column: 'name', title: 'Name', sortable: true },
                    ]}
                />
            </div>
        );
    }
}
