export class Logger {

    static levels = {
        Debug: 4,
        Info: 3,
        Warn: 2,
        Error: 1,
        Fatal: 0
    };

    static options = {
        enabled: false,
        level: Logger.levels.Debug
    };

    static writeDebug(message, context) {
        if (Logger.options.enabled && Logger.options.level >= Logger.levels.Debug) {
            if (context) {
                console.debug('DEBUG: ' + message, context);
            }
            else {
                console.debug('DEBUG: ' + message);
            }
        }
    }

    static writeInfo(message, context) {
        if (Logger.options.enabled && Logger.options.level >= Logger.levels.Info) {
            if (context) {
                console.info('INFO: ' + message, context);
            }
            else {
                console.info('INFO: ' + message);
            }
        }
    }

    static writeWarn(message, context) {
        if (Logger.options.enabled && Logger.options.level >= Logger.levels.Warn) {
            if (context) {
                console.warn('WARN: ' + message, context);
            }
            else {
                console.warn('WARN: ' + message);
            }
        }
    }

    static writeError(message, context) {
        if (Logger.options.enabled && Logger.options.level >= Logger.levels.Error) {
            if (context) {
                console.error('ERROR: ' + message, context);
            }
            else {
                console.error('ERROR: ' + message);
            }
        }
    }

    static writeFatal(message, context) {
        if (Logger.options.enabled && Logger.options.level >= Logger.levels.Fatal) {
            if (context) {
                console.error('FATAL: ' + message, context);
            }
            else {
                console.error('FATAL: ' + message);
            }
        }
    }

}
