import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Logger } from './infrastructure/Logger';
import { Configuration } from './infrastructure/Configuration';
import { PushCommandClient } from './infrastructure/NotificationSubscribers';
import { CapabilityName } from './infrastructure/Constants';
import { CapabilityRegistry, Capabilities } from './infrastructure/Capabilities';
import { BackSplashComponent } from './capabilities/BackSplashComponent';
import { LoaderComponent } from './capabilities/LoaderComponent';
import { ModalComponent } from './capabilities/ModalComponent';
import { NotificationComponent } from './capabilities/NotificationComponent';
import { CapabilitiesContext, SettingsContext, UserContext } from './infrastructure/Contexts';

import { Home } from './pages/Home';
import { PaymentMethodListView } from './pages/PaymentMethodView';
import { PaymentProviderListView } from './pages/PaymentProviderView';
import { TransactionListView } from './pages/TransactionListView';
import { TransactionView } from './pages/TransactionView';
import { WebhookConfigurationView } from './pages/WebhookConfigurationView';
import { ListDemo } from './pages/ListDemo';
import { PendingTasksView } from './pages/PendingTasksView';



import './global.css'
import './payment.css'
import { CreateNewWebhook } from './pages/CreateNewWebhook';


export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        Logger.options.enabled = true;

        this.state = {
            config: null
        }

        this.configuration = new Configuration();
        this.capabilities = new CapabilityRegistry();
        this.configuration.getConfigAsync()
            .then(data => {
                var notificationClient = new PushCommandClient(data.settings['Notifications:PushWebSocketUri'], data.settings['Security:ClientId'], data.user.sid);
                var notificationRegistry = notificationClient.getSubscriberRegistry();
                notificationRegistry.registerHandler(this.onCommandReceived);

                var config = {
                    user: data.user,
                    settings: data.settings,
                    capabilities: this.capabilities,
                    notificationSubscribers: notificationRegistry
                }

                Logger.writeDebug('config', config);
                this.setState({
                    config: config
                });
            })
            .catch(ex => {
               window.location.replace('account/login');
            });
    }


    onCommandReceived(command) {
    }


    render() {
        return (
            <div className='payment'>

                <BackSplashComponent capabilityRegistry={this.capabilities} id={CapabilityName.BackSplash} />
                <LoaderComponent capabilityRegistry={this.capabilities} id={CapabilityName.Loader} />
                <ModalComponent capabilityRegistry={this.capabilities} id={CapabilityName.Modal} />
                <NotificationComponent capabilityRegistry={this.capabilities} id={CapabilityName.Notification} />

                {this.state.config &&
                    <SettingsContext.Provider value={this.state.config.settings}>
                        <UserContext.Provider value={this.state.config.user}>
                            <CapabilitiesContext.Provider value={this.state.config.capabilities}>
                                <Layout>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/paymentmethod' component={PaymentMethodListView} />
                                <Route exact path='/paymentprovider' component={PaymentProviderListView} />
                                <Route exact path='/transactions' component={TransactionListView} />
                                <Route exact path='/transactions/:id' component={TransactionView} />
                                <Route exact path='/webhookconfig' component={WebhookConfigurationView} />
                                <Route exact path='/webhookconfig/new' component={CreateNewWebhook} />
                                <Route exact path='/listdemo' component={ListDemo} />
                                    <Route exact path='/pendingtasks' component={PendingTasksView} />
                                </Layout>
                            </CapabilitiesContext.Provider>
                        </UserContext.Provider>
                    </SettingsContext.Provider>
                }
            </div>
        );
    }
}
