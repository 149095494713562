import React from 'react';
import { Popover } from '../core/Popover';
import { CornerRightUp, CornerRightDown } from 'react-feather';

export const JournalAmountDisplay = ({ journal }) => {
    return (
        <Popover
            onRenderPopup={() => {
                return (
                    <div className='journal'>
                        {journal && journal.id &&
                            <div className='row'>
                                <div className='column col-6'>Journal</div>
                                <div className='column col-6 text-align-right color-orange'>{journal.id}</div>
                            </div>
                        }
                        {journal && journal.journalTypeId &&
                            <div className='row'>
                                <div className='column col-6'>Type</div>
                                <div className='column col-6 text-align-right color-orange'>{journal.journalTypeId}</div>
                            </div>
                        }
                        {journal && journal.currency &&
                            <div className='row'>
                                <div className='column col-6'>Currency</div>
                                <div className='column col-6 text-align-right color-orange'>{journal.currency}</div>
                            </div>
                        }
                        {journal && journal.transactionAmount &&
                            <div className='row'>
                                <div className='column col-6'>Amount</div>
                                <div className='column col-6 text-align-right color-orange'>{journal.transactionAmount}</div>
                            </div>
                        }
                    </div>
                )
            }}>
            <div className={journal && journal.journalTypeId == 'DEBIT' ? 'color-green' : 'color-orange'}>
                <div className='col-sm-hidden col-md-hidden'>
                    {journal && journal.journalTypeId == 'DEBIT'
                        ? <CornerRightDown className='icon' />
                        : <CornerRightUp className='icon' />
                    }
                </div>
                {journal 
                    ? <span>{journal.currency} {(parseFloat(journal.transactionAmount) / 100).toFixed(2)}</span>
                    : <span>--</span>
                }
            </div>
        </Popover>
    )
}
