import React  from 'react';

export const Alert = ({ children, type }) => {

    const getClassName = () => {

        let result = 'alert';

        switch (type) {
            case 'warn':
                result += ' warn';
                break;
            case 'error':
                result += ' error';
                break;
            case 'success':
                result += ' success';
                break;
            default:
                result += ' info';
                break;
        }
        return result;
    }
    return (
        <div className={getClassName()}>
            <div className='alert-content'>{children}</div>
        </div>
    );
}
