import React, { useContext, useEffect, useState, useRef } from 'react';
import { Textbox } from '../core/Textbox';
import { DropdownList } from '../core/DropdownList';
import { DotNet } from '../core/Utils';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ResponseStatus, Payment } from '../core/Payment';
import { SkipBack, SkipForward } from 'react-feather';



export const CapturePaymentWizard = ({ onApproved }) => {

    const [list, setList] = useState(null);

    const [provider, setProvider] = useState(null);
    const [method, setMethod] = useState(null);
    const [referenceType, setReferenceType] = useState("");
    const [config, setConfig] = useState({
        cardNumber: 'Card Number',
        cvv: 'CVV',
        expirationDate: 'MM/YY',
        postalCode: 'Postal Code',
        backgroundColor: '#FFF'
    });
    const [stepIndex, setStepIndex] = useState(0);
    const [canProceed, setCanProceed] = useState(false);
    const [payment, setPayment] = useState(null);
    const [processingResult, setProcessingResult] = useState(null);

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        var result = [];
        var dataAccess = new ApiDataAccess('api/provider');
        dataAccess.get('/')
            .then(items => {

                items.forEach(item => {
                    Object.keys(item).forEach(key => {
                        result.push(item[key]);
                    });
                });

                console.log('LIST', result);
                setList(result);
            });
    }

    const getReferenceTypes = () => {
        return [
            { id: 1, name: "order" },
            { id: 2, name: "invoice" },
        ];
    }
    const getRegions = () => {
        return [
            { id: 1, name: "en" },
            { id: 2, name: "de-DE" },
            { id: 3, name: "es-US" },
        ];
    }
    const getProviders = () => {
        var result = [];

        var providers = list
            .map(p => p.providerName)
            .filter((providerName, index, arr) => arr.indexOf(providerName) == index)
            .sort();

        providers.forEach((item, idx) => {
            result.push({ id: idx, name: item });
        })

        return result;
    }

    const getMethods = (provider) => {
        var result = [];

        if (provider) {
            list.forEach((item, idx) => {
                if (item.providerName == provider.name) {
                    result.push({ id: idx, name: item.paymentMethodName })
                }
            })
        }
        return result;
    }

    const getConfig = (provider, method) => {
        var result = [];
        if (provider && method) {
            list.forEach((item) => {
                if (item.providerName == provider.name && item.paymentMethodName == method.name) {
                    result = item.items;
                }
            });
        }
        return result;
    }


    const onNextClick = () => {
        let si = stepIndex;

        if (si < 2) {
            si++
            setStepIndex(si);
        }
        else if (si == 2) {
            payment.submit({});
        }
    }

    const onPreviousClick = () => {
        let si = stepIndex;
        if (si > 0) {
            si = si - 1;
        }
        setStepIndex(si);
        setProcessingResult(null);
    }

    const onValidated = (valid) => {
        setCanProceed(valid);
    }

    const setConfigValue = (id, value, valid) => {

        console.log(valid);

        if (valid) {
            config[id] = value;
        }
        else {
            config[id] = null;
        }
        setConfig(config);
    }

    const applyAmount = (value, valid) => {
        if (valid) {
            setConfigValue('amount', parseInt(value) * 100, valid);
            setConfigValue('txtAmount', value, valid);
        }
    }

    const onProcessed = (status, data) => {
        console.log("onProcessed", status, data);
        var result = {
            status: status,
            data: data,
            transactionId: data.transactionId,
        };
        if (status === ResponseStatus.Approved && data && data.transactionId > 0) {

            setProcessingResult(null);
            if (onApproved) {
                onApproved(result)
            }
        }
        else if (status == ResponseStatus.ApprovedPending) {
            setProcessingResult(null);

            if (onApproved) {
                onApproved(result);
            }
        }
        else {
            setProcessingResult(result);
        }
    }

    return (
        <div>
            {list &&
                <div>
                    {stepIndex == 0 &&
                        <div>
                            <div className="row">
                                <div className='column w2'>Provider</div>
                                <div className='column w2'><DropdownList value={provider ? provider.id : null} items={getProviders()} onChange={item => { setProvider(item); setCanProceed(false); }} /></div>
                            </div>

                            {provider &&
                                <div className="row">
                                    <div className='column w2'>Method</div>
                                    <div className='column w2'><DropdownList value={method ? method.id : null} items={getMethods(provider)} onChange={item => { setMethod(item); setCanProceed(true); }} /></div>
                                </div>
                            }
                        </div>
                    }


                    {stepIndex == 1 &&
                        <div>
                            <div className="row">
                                <div className='column w2'>Amount</div>
                                <div className='column w2'><Textbox id={'amount'} value={config && config.txtAmount ? config.txtAmount : ''} required={true} regex={'[0-9]*(\.[0-9]{0,2})?'} onValidated={(id, valid) => { onValidated(valid) }} onChange={(value, valid, id) => { applyAmount(value, valid) }} placeholder='Amount' /></div>
                            </div>

                            <div className="row">
                                <div className='column w2'>Currency</div>
                                <div className='column w2'><Textbox id={'currency'} value={config && config.currency ? config.currency : ''} required={true} regex={'[a-zA-Z]{1,3}'} maxLength={3} onValidated={(id, valid) => { onValidated(valid) }} onChange={(value, valid, id) => { setConfigValue(id, value, valid) }} placeholder='Currency' /></div>
                            </div>

                            <div className="row">
                                <div className='column w2'>Reference / Order Nr.</div>
                                <div className='column w2'><Textbox id={'referenceId'} value={config && config.referenceId ? config.referenceId : ''} required={true} onValidated={(id, valid) => { onValidated(valid) }} onChange={(value, valid, id) => { setConfigValue(id, value, valid) }} placeholder='Reference' /></div>
                            </div>

                            <div className="row">
                                <div className="column w2">Reference Type</div>
                                <div className="column w2"><DropdownList value={1} items={getReferenceTypes()} onChange={item => { console.log(item); setConfigValue("referenceType", item.name, true); }} /></div>
                            </div>

                            <div className="row">
                                <div className="column w2">Region</div>
                                <div className="column w2"><DropdownList value={1} items={getRegions()} onChange={item => { console.log(item); setConfigValue("region", item.name, true); }} /></div>
                            </div>

                            {getConfig(provider, method).map((item, idx) => {
                                return (
                                    <div className="row" key={`row${idx}`}>
                                        <div className='column w2'>{item.value}</div>
                                        <div className='column w2'><Textbox id={item.value} value={config && config[item.value] ? config[item.value] : ''} required={true} onValidated={(id, valid) => { onValidated(valid) }} onChange={(value, valid, id) => { setConfigValue(id, value, valid) }} onValidating={txt => { return DotNet.IsValueOfType(txt, item.name) }} placeholder={item.name} /></div>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    {stepIndex == 2 &&
                    <div>
                            <PaymentComponent config={config} provider={provider.name} method={method.name} amount={config.amount} currency={config.currency} referenceId={config.referenceId} onInit={payment => { setPayment(payment) }} onProcessed={(status, data) => { onProcessed(status, data) }} />
                            {processingResult &&
                                <div>
                                    <p>Status: {processingResult.status}</p>
                                    <p>Reason Code: {processingResult.data ? processingResult.data.reasonCode : ''}</p>
                                    <p>Transaction Id: {processingResult.transactionId ? processingResult.transactionId : 'Implementation did not return the required Transaction Id'}</p>
                                </div>
                            }
                        </div>
                    }

                    {stepIndex > 0 &&
                        <button className='btn btn-primary' onClick={() => { onPreviousClick() }}><SkipBack className='icon' /> <span className='col-sm-hidden'>Previous</span></button>
                    }

                <button className='btn btn-primary pull-right' disabled={!canProceed} onClick={() => { onNextClick() }}><span className='col-sm-hidden'>Next</span> <SkipForward className='icon' /></button>
                </div>
            }
        </div>
    )

}



export const PaymentComponent = ({ config, provider, method, amount, currency, referenceId, onInit, onProcessed }) => {

    useEffect(() => {

        Payment.CreateForm(
            '',
            provider,
            method,
            amount,
            currency,
            referenceId,
            'payment-container',
            config,
            (status, data) => {
                if (onProcessed) {
                    onProcessed(status, data);
                }
            }
        )
            .then(payment => {
                if (onInit) {
                    onInit(payment);
                }
            })
            .catch(ex => {
                alert(ex);
            });
    }, []);

    return (
        <div id="payment-container"></div>
    )
}