import React  from 'react';
import { Capability } from '../infrastructure/Capabilities';

export class BackSplashComponent extends Capability {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    show() {
        this.setState({
            visible: true
        })
    }

    hide() {
        this.setState({
            visible: false
        })
    }

    render() {
        return (
            <div>
                {this.state.visible &&
                    <div className='backSplash' />
                }
            </div>
        )
    }
}