import React, { useState } from 'react';

function recursiveMap(children, fn) {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, fn)
            });
        }

        return fn(child);
    });
}

export const Form = ({ children, onValidated }) => {

    const [callbacks, setCallbacks] = useState({});
    const [controls, setControls] = useState({});


    const onControlValidated = (id, valid) => {

        if (callbacks[id]) {
            callbacks[id](id, valid);
        }

        controls[id] = valid;
        setControls(controls);        

        if (onValidated && typeof onValidated === 'function') {
            onValidated(isContextValid());
        }       
    }

    const isContextValid = () => {
        var isValid = true;
        Object.keys(controls).forEach(key => {
            if (controls[key] === false) {
                isValid = false;
            }
        });
        return isValid;
    }



    const injectHandler = (child) => {

        if (typeof child.type === 'function' && child.props && child.props.id) {

            if (child.props.onValidated && typeof child.props.onValidated === 'function') {
                callbacks[child.props.id] = child.props.onValidated;
                setCallbacks(callbacks);
            }

            return React.cloneElement(child, {
                onValidated: (id, valid) => { onControlValidated(id, valid) }
            })
        }
        else {
            return child;
        }

    }

    return (
        <div>
            {recursiveMap(children, child => injectHandler(child))}
        </div>
        )
}


