import React from "react";

export const Row = ({ children }) => {

    return (
        <div className='row'>
            {children.map((child, idx) => {
                return React.cloneElement(child, { key: 'col' + idx, totalCols: children.length });
            })}
        </div>
    );
}

export const Col = ({ children, totalCols, className, sm, md, lg }) => {

    const getClass = () => {
        var result = 'column';

        if (sm || md || lg) {
            if (sm) {
                result += sm != 'hidden' ? ` col-sm-${sm}` : ' col-sm-hidden';
            }

            if (md) {
                result += md != 'hidden' ? ` col-md-${md}` : ' col-md-hidden';
            }

            if (lg) {
                result += lg != 'hidden' ? ` col-lg-${lg}` : ' col-lg-hidden';
            }
        }
        else {
            result += ` w${totalCols}`;
        }

        return result;
    }

    return (
        <div className={`${getClass()} ${className}`}>
            {children}
        </div>
    );
};