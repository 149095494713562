import React from 'react';
import { Check, X, Clock } from 'react-feather';

export const Status = {
    Success: 0,
    Failure: 1,
    Pending: 2
}

const statusLookup = {
    "SUCCE": Status.Success,
    "CHPND": Status.Pending,
    "DECLN": Status.Failure,
    "ERROR": Status.Failure
};

export const StatusDisplay = ({ status }) => {
    const getIcon = () => {
        console.log("from statusdisplay", status)
        switch (status) {
            case "SUCCE":
                return (<span><Check className='icon' /> Success</span>)
            case "ERROR":
            case "DECLN":
                return (<span><X className='icon' /> Failure</span>)
            case "CHPND":
                return (<span><Clock className='icon' />Pending</span>)
            default:
                return (<span> </span>)
        }
    }

    const getStatusColor = () => {
        if (statusLookup[status] == Status.Success) {
            return "color-valid";
        }
        else if (statusLookup[status] == Status.Pending) {
            return "color-orange";
        }
        return "color-error";
    }

    return (
        <div className={getStatusColor()}>
            {getIcon()}
        </div>
    )
}