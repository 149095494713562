import React, { Component } from 'react';

import './controls.css'

export class Toggle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            on: this.props.on
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.disabled !== true) {
            var on = this.state.on;
            var newValue = !on;

            this.setState({
                on: newValue
            });

            if (this.props.onChange) {
                this.props.onChange(newValue, this);
            }
        }
    }


    getLabel() {

        var on = this.state.on;
        if (on) {
            if (this.props.onLabel) {
                return this.props.onLabel;
            }
        }
        else {
            if (this.props.offLabel) {
                return this.props.offLabel;
            }
        }
    }

    render() {
        return (
            <div className={this.props.disabled ? 'toggle-switch' : 'toggle-switch disabled'}>
                <div className={this.state.on == true ? 'on' : 'off'}>
                    <div className='container' onClick={this.onClick}>
                        <div className='switch'>
                        </div>
                    </div>
                    <div className='turned-on-label'>
                        <span>{this.getLabel()}</span>
                    </div>
                </div>
            </div>
        );
    }
}
