import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Alert } from '../core/Alert';
import { Card } from '../core/Card';
import { Row, Col } from '../core/Grid';
import { List } from "../core/List";
import { DollarSign } from 'react-feather';
import { Textbox } from '../core/Textbox';
import { Toggle } from '../core/Toggle';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';

export const CreateNewWebhook = ({ match, history }) => {

    const [loaded, setLoaded] = useState(false);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const [webhooks, setWebhooks] = useState(null);

    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);

    useEffect(() => {
        const loadWebhookConfig = async () => {
            //await init();
            setLoaded(true);
        }
        loadWebhookConfig();
    }, []);

    const init = async () => {
        try {
            var dataAccess = new ApiDataAccess('/api');

            var result = await dataAccess.get(`/webhook`);
            console.log(result);
            if (result) {
                setWebhooks(result);
            }
        }
        catch {
            setWebhooks(null);
        }
        setLoaded(true);
    }

    return (
        <div>
            <h1>Webhook Configuration</h1>
            {loaded &&
                <div>
                    <CreateNewWebhookForm paymentProviderId={match} notifications={notifications} history={history} />
                </div>
            }
        </div>
    )
}

export const CreateNewWebhookForm = ({ history, notifications }) => {

    const [loaded, setLoaded] = useState(false);
    const [formValue, setFormValue] = useState({ description: '', url: '', paymentProviderId: '' });
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [paymentProviders, setPaymentProviders] = useState({});

    const dataAccess = new ApiDataAccess('/api/webhook');
    const paymentProviderDataAccess = new ApiDataAccess('/api/provider');

    useEffect(() => {
        const load = async () => {
            var paymentProviders = await paymentProviderDataAccess.get("/all");
            console.log(paymentProviders);
            if (paymentProviderDataAccess) {
                setPaymentProviders(paymentProviders.map(x => {
                    return {
                        id: x.id,
                        name: x.name
                    };
                }))
            }
            setLoaded(true);
        }
        load();
    }, []);

    const handleChange = event => {
        const value = event.target.value;

        let update = formValue;
        update[event.target.id] = value;

        setFormValue(update);
    }

    const onSubmit = async data => {
        console.log("submitted", data);
        try {
            var response = await dataAccess.post("/", {
                paymentProviderId: data.paymentProviderId,
                description: data.description,
                url: data.url
            });

            if (response && response.webhookId) {
                notifications.add("info", "Create webhook succeeded", "Webhook created. The webhook id is " + response.webhookId);
                history.push("/webhookconfig");
            }
            else {
                notifications.add("error", "Create webhook failed", "There was an error trying to create the webhook. Please try your request again.");
            }
        }
        catch (e) {
            console.log(e);
            notifications.add("error", "Create webhook failed", "There was an error trying to create the webhook. Please try your request again.");

        }
    }

    return (
        loaded && <div>
            <Card title="Create new webhook">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="description">Description</label>
                        <input id="description" className={errors.description ? "control textbox error" : "control textbox"} type="text" placeholder="description" onChange={handleChange}
                            {
                            ...register("description", {
                                required: "Description is required",
                                maxLength: 255
                            })} />
                        {errors.description && <span className="color-error">{errors.description.message}</span>}
                    </div>
                    <div>
                        <label htmlFor="paymentProviderId">Payment Provider</label>
                        <select name="paymentProviderId" onChange={handleChange}
                            className={errors.paymentProviderId ? "control dropdown error" : "control dropdown"}
                            defaultValue="-1"
                            {
                            ...register("paymentProviderId", {
                                required: "Payment provider is required",
                            })}>
                            <option value="">Please select a provider...</option>
                            {paymentProviders.map(x => <option value={x.id}>{x.name}</option>)}
                        </select>
                        {errors.paymentProviderId && <span className="color-error">{errors.paymentProviderId.message}</span>}
                    </div>
                    <div>
                        <label htmlFor="url">Url</label>
                        <input id="url" className={errors.url ? "control textbox error" : "control textbox"} type="text" placeholder="url" onChange={handleChange}
                            {...register("url", {
                                required: "A valid http/https url is required",
                                maxLength: 512,
                                pattern: {
                                    value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                                    message: "Please enter a valid http/https url"
                                }
                            })} />
                        {errors.url && <span className="color-error">{errors.url.message}</span>}
                    </div>
                    <input type="submit" className="btn btn-primary" value="Create" />
                </form>
            </Card>
        </div>
    )
}