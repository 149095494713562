import React, { useContext, useEffect, useState } from 'react';
import { Alert } from '../core/Alert';
import { List } from "../core/List";
import { Toggle } from '../core/Toggle';
import { DollarSign, Trash, PlusSquare } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';

export const WebhookConfigurationView = ({ match, history }) => {

    const [loaded, setLoaded] = useState(false);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const [webhooks, setWebhooks] = useState(null);

    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const dataAccess = new ApiDataAccess('/api/webhook');

    useEffect(() => {
        const loadWebhookConfig = async () => {
            await init();
        }
        loadWebhookConfig();
    }, []);

    const init = async () => {
        try {
            var dataAccess = new ApiDataAccess('/api');

            var result = await dataAccess.get(`/webhook`);
            console.log(result);
            if (result) {
                setWebhooks(result);
            }
        }
        catch {
            setWebhooks(null);
        }
        setLoaded(true);
    }

    const onToggle = async (item, on) => {
        console.log(item);
        if (on) {
            await dataAccess.put(`/${item.webhookId}/enable`);
        }
        else {
            await dataAccess.put(`/${item.webhookId}/disable`);
        }
    }

    const onDeleteWebhook = async (item) => {
        var response = await dataAccess.delete(`/${item.webhookId}/delete`);
        if (response) {
            notifications.add("info", "Delete webhook succeeded", "Webhook " + item.webhookId + " deleted.");
            history.go(0);
        }
    }

    return (
        <div>
            <h1>Webhook Configuration</h1>
            {loaded &&
                <div>
                    <button className='btn btn-primary' onClick={() => { history.push("/webhookconfig/new") }}><PlusSquare className='icon' /> Create New</button>
                    <List
                        controller="api/webhook"
                        action="/"
                        headers={[
                            { column: 'webhookId', title: 'Id', sortable: false, className: 'col-1 col-sm-1' },
                            { column: 'description', title: 'Description', sortable: false, className: 'col-2 col-md-2' },
                            { column: 'url', title: 'Url', sortable: false, className: 'col-4 col-md-4' },
                            { column: 'createUser', title: 'Create User', sortable: false, className: 'col-1 col-sm-2' },
                            { column: 'isEnabled', title: 'Enabled', sortable: false, className: 'col-2 col-sm-2' },
                            { column: 'webhookStatusId', title: 'Webhook Status', sortable: false, className: 'col-1 col-md-1' },
                            { column: 'trash', title: '', sortable: false, className: 'col-1 col-sm-1' },
                            { column: 'createDate', title: 'Create Date', sortable: false, className: 'col-2 col-md-2' }
                        ]}
                        onRenderRow={(column, item, header) => {
                            switch (column) {
                                case 'isEnabled':
                                    return (<span><Toggle on={item.isEnabled} onChange={on => { onToggle(item, on) }} /></span>);
                                case 'trash':
                                    return (<span><Trash className="delete-trash-icon" onClick={() => onDeleteWebhook(item)}></Trash></span>)
                                default:
                                    if (item[column] != null) {
                                        return (<span>{item[column]}</span>);
                                    }
                                    return <span>&nbsp;</span>;
                            }
                        }}
                        className='transaction-list'
                    >
                    </List>
                </div>
            }

            {loaded && !webhooks &&
                <Alert type='error'>Webhook configuration could not be loaded.</Alert>
            }
        </div>
    )
}