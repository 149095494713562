import React, { useState, useEffect, useContext } from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Alert } from '../core/Alert';
import { Card } from '../core/Card';
import { Row, Col } from '../core/Grid';
import { DateTime } from '../core/DateDisplay';
import { Status, StatusDisplay } from '../core/StatusDisplay';
import { String } from '../core/Utils';
import { JournalAmountDisplay } from '../components/JournalAmountDisplay';
import { Toggle } from '../core/Toggle';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { CapabilityName } from '../infrastructure/Constants';
import { Textbox, Textarea } from '../core/Textbox';
import { DropdownList } from '../core/DropdownList';
import { Utils, DotNetType, DotNet } from '../core/Utils';

export const TransactionView = ({ match, history }) => {

    const [loaded, setLoaded] = useState(false);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const [transactionInfo, setTransactionInfo] = useState(null);
    const [checkAcknowledgement, setCheckAcknowledgement] = useState(false);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const [externalDocumentNo, setExternalDocumentNo] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            var transactionInfo = await loadAsync(match.params.id);

            console.log(transactionInfo);
            setTransactionInfo(transactionInfo);
        }
        catch {
            setTransactionInfo(null);
        }
        setLoaded(true);
    }

    const loadAsync = async (id) => {

        var items = await loadPaymentMethods();
        setPaymentMethods(items);

        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('/api/transaction');
            Promise.all([
                dataAccess.get(`/${id}`),
                dataAccess.get(`/${id}/audit`),
                dataAccess.get(`/${id}/journal`)
            ])
                .then(results => {
                    var transactionInfo = {
                        transaction: results[0],
                        audit: results[1],
                        journal: results[2],
                        metaData: results[1] && results[1].providerTransactionMetadata ? JSON.parse(results[1].providerTransactionMetadata) : null,
                        epacMetadata: results[0] && results[0].audit && results[0].audit.epacMetadata ? JSON.parse(results[0].audit.epacMetadata) : null
                    }
                    resolve(transactionInfo);
                })
                .catch(ex => {
                    reject(ex);
                })
        });
    }

    const loadPaymentMethods = async () => {
        var dataAccess = new ApiDataAccess('api/method');
        var items = await dataAccess.get('/');
        return items;
    }

    const approvePendingTransaction = async id => {
        setCheckAcknowledgement(false);

        var request = { TransactionId: id, ExternalDocumentNo: externalDocumentNo, PaymentMethod: paymentMethod.name }
        console.log("request: ", request);

        var dataAccess = new ApiDataAccess(`/api/transaction`);
        var response = await dataAccess.put(`/${id}/complete`, request);

        notifications.add("info", "Payment Success", "The payment was approved.");
        history.push(`/transactions`);
    }


    return (
        <div className='transactionView'>
            <h1>Transaction {match.params.id}</h1>
            {loaded && transactionInfo &&
                <div>
                    {transactionInfo.transaction && transactionInfo.transaction.transactionStateId == "CHPND" && transactionInfo.transaction.method.name.toLowerCase() === "check" &&
                        <Card title="Approval">
                            <Row>
                                <Col className='caption'>Payment Method</Col>
                                <Col className='content'>
                                    <DropdownList id='dataType' required={true} items={paymentMethods} onChange={item => { setPaymentMethod(item) }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Document No.</Col>
                                <Col className='content'>
                                    <Textbox id='document_no' required={true} maxLength={20} onChange={item => { setExternalDocumentNo(item) }} onValidating={txt => { return Utils.MatchesRegex('^[a-zA-Z0-9-]*$', txt) }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='caption'>I certify that I have received a check for the pending charge of {(parseFloat(transactionInfo.journal.transactionAmount) / 100).toFixed(2)} {transactionInfo.journal.currency}.</Col>
                                <Col className='content'>
                                    <Toggle onChange={value => {
                                        setCheckAcknowledgement(value);
                                    }}></Toggle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                                <Col>
                                    <button className='btn btn-primary'
                                        onClick={async () => {
                                            await approvePendingTransaction(match.params.id);
                                        }}
                                        disabled={!checkAcknowledgement}>
                                        Approve Payment</button>
                                </Col>
                            </Row>
                        </Card>
                    }

                    {transactionInfo.transaction &&
                        <Card title='Transaction'>
                            <Row>
                                <Col className='caption'>Reference / Order Nr.</Col>
                                <Col className='content'>{transactionInfo.transaction.referenceId ? transactionInfo.transaction.referenceId : 'N/A'}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Provider</Col>
                                <Col className='content'>{transactionInfo.transaction.provider ? transactionInfo.transaction.provider.name : 'N/A'}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Method</Col>
                                <Col className='content'>{transactionInfo.transaction.method ? transactionInfo.transaction.method.name : 'N/A'}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Date (your local time)</Col>
                                <Col className='content'><DateTime dateTime={transactionInfo.transaction.createDate} showTimeZone={true} /></Col>
                            </Row>
                            <Row>
                                <Col className='caption'>User</Col>
                                <Col className='content'>{transactionInfo.transaction.createUser}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Plant Id</Col>
                                <Col className='content'>{transactionInfo.transaction.plantId}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Result</Col>
                                <Col className='content'><StatusDisplay status={transactionInfo.transaction.transactionStateId} /></Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Reference Type</Col>
                                <Col className='content'>{transactionInfo.transaction.referenceTypeId === "INVCE" ? "Invoice" : "Order"}</Col>
                            </Row>
                        </Card>
                    }

                    {transactionInfo.journal &&
                        <Card title='Journal'>
                            <Row>
                                <Col className='caption'>Type</Col>
                                <Col className='content'>{transactionInfo.journal.journalTypeId}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Currency</Col>
                                <Col className='content'>{transactionInfo.journal.currency}</Col>
                            </Row>
                            <Row>
                                <Col className='caption'>Amount</Col>
                                <Col className='content'><JournalAmountDisplay journal={transactionInfo.journal} /></Col>
                            </Row>
                        </Card>
                    }

                    {transactionInfo.audit &&
                        <Card title='Audit'>
                            <Row>
                                <Col className='caption'>Provider Reference</Col>
                                <Col className='content'>{transactionInfo.audit.providerTransactionReferenceId}</Col>
                            </Row>

                            {transactionInfo.metaData &&
                                <div>
                                    {Object.keys(transactionInfo.metaData).map((key, idx) => {
                                        return (
                                            <Row key={`row${idx}`}>
                                                <Col className='caption'>{String.PrettyPrintFromCamelCase(key)}</Col>
                                                <Col className='content overflow-hidden'>{String.IsJson(transactionInfo.metaData[key]) ? <pre>{JSON.stringify(transactionInfo.metaData[key], undefined, 2)}</pre> : transactionInfo.metaData[key]}</Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            }
                        </Card>
                    }

                    {transactionInfo.epacMetadata &&
                        <Card title='Reference'>

                            {transactionInfo.epacMetadata &&
                                <div>
                                    {Object.keys(transactionInfo.epacMetadata).map((key, idx) => {
                                        return (
                                            <Row key={`row${idx}`}>
                                                <Col className='caption'>{String.PrettyPrintFromCamelCase(key)}</Col>
                                                <Col className='content overflow-hidden'>{String.IsJson(transactionInfo.epacMetadata[key]) ? <pre>{JSON.stringify(transactionInfo.epacMetadata[key], undefined, 2)}</pre> : transactionInfo.epacMetadata[key]}</Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            }
                        </Card>
                    }

                </div>
            }

            {loaded && !transactionInfo &&
                <Alert type='error'>This Transaction was not found!</Alert>
            }
        </div>
    )
}