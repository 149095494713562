//-----------------------------------------------------------------
// Shared
//-----------------------------------------------------------------

export const PaymentMethod = {
    CreditCard: 'CC',
    eCheck: 'eCheck',
    ACH: 'ACH'
}

export const ReasonCode = {
    None: 'NONE',
    NotFound: 'NOT_FOUND',
    InvalidCardNumber: 'INVALID_CARD_NUMBER',
    InvalidCvv: 'INVALID_CVV',
    InvalidExpirationDate: 'INVALID_EXPIRATION_DATE',
    InvalidPostalCode: 'INVALID_POSTAL_CODE',
    PaymentResubmitted: 'PAYMENT_RESUBMITTED',
    InvalidAccountNumber: 'INVALID_ACCOUNT_NUMBER',
    InvalidRoutingNumber: 'INVALID_ROUTING_NUMBER',
    PaymentProviderError: 'PAYMENT_PROVIDER_ERROR'
}

export const ResponseStatus = {
    Rejected: 'REJECTED',
    Declined: 'DECLINED',
    Approved: 'APPROVED',
    ApprovedPending: 'APPROVED_PENDING'
}


export class MessageRouter {
    constructor() {
        this.handlers = {};
    }

    addHandler(command, handler) {
        this.handlers[command] = handler;
    }

    processMessage(message) {
        if (this.handlers[message.command]) {
            this.handlers[message.command](message.data);
        }
    }
}

export class Messaging {

    constructor(target, router) {
        var self = this;
        this.router = router;
        this.target = target;

        window.onmessage = function (e) {
            self.onReceive(e.data);
        }
    }

    onReceive(message) {
        console.log('Message received:', message);
        this.router.processMessage(message);
    }

    send(command, data) {
        let message = {
            command: command,
            data: data ? data : {}
        }

        console.log('Message sent:', message);
        this.target.postMessage(message, '*');
    }
}

//-----------------------------------------------------------------
// Payment API - All Providers
//-----------------------------------------------------------------

export class PaymentProvider {

    constructor(onConfigurationReceivedCallback, onSubmitCallback, status = ReasonCode.None) {

        console.log("PaymentProviderConstructor", onConfigurationReceivedCallback.constructor.name);

        var messageRouter = new MessageRouter();
        messageRouter.addHandler('setConfiguration', data => { this.onConfigurationReceived(onConfigurationReceivedCallback, data) });
        messageRouter.addHandler('submit', data => { this.onSubmit(onSubmitCallback, data) });

        this.messaging = new Messaging(window.top, messageRouter);
        this.messaging.send('init', { status: status });
    }

    onConfigurationReceived(onConfigurationReceivedCallback, data) {
        if (onConfigurationReceivedCallback) {
            onConfigurationReceivedCallback(data);
        }
    }

    onSubmit(onSubmitCallback, data) {
        if (onSubmitCallback) {
            onSubmitCallback(data);
        }
    }

    sendRejected(reasonCode, data = null) {
        var response = {
            transactionId: null,
            reasonCode: reasonCode,
            data: data
        };
        this.messaging.send(ResponseStatus.Rejected, response);
    }

    sendDeclined(transactionId, reasonCode = ReasonCode.None, data = null) {
        var response = {
            transactionId: transactionId,
            reasonCode: reasonCode,
            data: data
        };
        this.messaging.send(ResponseStatus.Declined, response);
    }

    sendApproved(transactionId, reasonCode = ReasonCode.None, data = null) {
        var response = {
            transactionId: transactionId,
            reasonCode: reasonCode,
            data: data
        };
        this.messaging.send(ResponseStatus.Approved, response);
    }

    sendApprovedPending(reasonCode = ReasonCode.None, data = null) {
        var response = {
            transactionId: null,
            reasonCode: reasonCode,
            data: data
        };
        this.messaging.send(ResponseStatus.ApprovedPending, response);
    }

}

//-----------------------------------------------------------------
// Client APP
//-----------------------------------------------------------------


export class Payment {

    constructor(context, amount, currency, referenceId, config, callback) {

        this.context = context;
        this.config = config ? config : {};
        this.config.idempotencyKey = Payment.GetIdempotencyKey();
        this.config.referenceId = referenceId ? referenceId : 'N/A';
        this.config.amount = amount;
        this.config.currency = currency;

        console.log("payment config", this.config);

        var messageRouter = new MessageRouter();
        messageRouter.addHandler(ResponseStatus.Rejected, data => { this.processAuthResult(ResponseStatus.Rejected, data, callback); });
        messageRouter.addHandler(ResponseStatus.Approved, data => { this.processAuthResult(ResponseStatus.Approved, data, callback); });
        messageRouter.addHandler(ResponseStatus.Declined, data => { this.processAuthResult(ResponseStatus.Declined, data, callback); });
        messageRouter.addHandler(ResponseStatus.ApprovedPending, data => { this.processAuthResult(ResponseStatus.ApprovedPending, data, callback); });

        this.messaging = new Messaging(this.context, messageRouter);
    }

    static GetIdempotencyKey() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    processAuthResult(status, data, callback) {
        if (callback) {
            callback(status, data);
        }
    }

    setConfiguration() {
        this.messaging.send('setConfiguration', this.config);
    }

    submit(data) {
        data.config = this.config;
        this.messaging.send('submit', data);
    }

    static Options = {
        width: '100%',
        frameborder: 0,
        scrolling: 'no',
        style: 'display: block'
    }


    static CreateForm(paymentApiBaseUrl, provider, paymentMethod, amount, currency, referenceId, containerId, config, callback, options = Payment.Options) {
        return new Promise((resolve, reject) => {

            var iframe = document.createElement('iframe');
            iframe.id = containerId;
            iframe.src = `${paymentApiBaseUrl}/api/markup/payment/ui/${provider}/${paymentMethod}`;
            iframe.setAttribute("allow", "payment");

            iframe.onload = () => {
                iframe.height = "";
                iframe.height = (iframe.contentWindow.document.body.scrollHeight - 20) + "px";
                iframe.scroll = "yes";
            }


            // Apply Options
            Object.keys(options).map(name => {
                iframe.setAttribute(name, options[name]);
            });

            // Configure Messaging and router
            var messageRouter = new MessageRouter();
            messageRouter.addHandler('init', data => {
           var payment = new Payment(iframe.contentWindow, amount, currency, referenceId, config, callback);
                payment.setConfiguration();
                resolve(payment);
            });
            var messaging = new Messaging(iframe.contentWindow, messageRouter);

            // Inject iFrame
            var container = document.getElementById(containerId);
            if (container) {
                container.replaceWith(iframe);
            }
            else {
                reject('Container not found!');
            }
        });
    }

}