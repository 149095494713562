import React, { Component } from 'react';
import { List } from '../core/List'



export class ListDemo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <List
                    controller='api/mock'
                    action='/list/pageable'
                    pageSize={10}          
                    sortColumn={'id'}
                    sortDesc={ true }
                    headers={[
                        { column: 'id', title: 'Id', width: 2, sortable: true, filterable: false },
                        { column: 'name', title: 'Name', width: 8, sortable: true, filterable: true },
                        { column: 'age', title: 'Age', width: 2, sortable: true, filterable: true }
                    ]}
                    onSelect={(data) => { console.log(data)} }
                />
            </div>
            )
    }

}
