import React from 'react';
import { Capability } from '../infrastructure/Capabilities';
import { XSquare } from 'react-feather';



export class ModalComponent extends Capability {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            valid: false,
            metaData: {}
        }

        this.onRenderContent = null;
        this.onRenderFooter = null;
        this.onRenderHeader = null;
        this.onShow = null;
        this.onHide = null;
    }

    static Callbacks = {
        onHide: null,
        onShow: null
    }


    show(onRenderContent, onRenderFooter, onRenderHeader, callbacks = ModalComponent.Callbacks) {
        this.onRenderContent = onRenderContent;
        this.onRenderFooter = onRenderFooter;
        this.onRenderHeader = onRenderHeader;
        this.callbacks = callbacks;

        this.setState({
            visible: true
        });

        if (this.callbacks && this.callbacks.onShow) {
            this.callbacks.onShow(this);
        }
    }

    hide() {
        this.setState({
            visible: false
        });

        if (this.callbacks && this.callbacks.onHide) {
            this.callbacks.onHide(this);
        }
    }

    setValid(valid) {
        this.setState({ valid: valid });
    }

    isValid() {
        return this.state.valid;
    }

    addMetaData(name, value) {
        var metaData = this.state.metaData;
        metaData[name] = value;
    }

    getMetaData(name) {
        var metaData = this.state.metaData;
        return metaData[name];
    }

    render() {
        return (
            <div>
                {this.state.visible &&
                    <div>
                        <div className='backSplash' />
                        <div className='modal'>
                            <div className='header'>
                                {this.onRenderHeader &&
                                    <div>{this.onRenderHeader(this)}</div>
                                }
                                <XSquare className='icon btn-close' onClick={() => { this.hide(); }} />
                            </div>

                            {this.onRenderContent &&
                                <div className='content'>
                                    {this.onRenderContent(this)}
                                </div>
                            }

                            {this.onRenderFooter &&
                                <div className='footer'>
                                    {this.onRenderFooter(this, this.state.valid)}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}
