import React, { useContext, useEffect, useState, useRef } from 'react';
import { Check, Slash, AlertCircle, CheckCircle } from 'react-feather';
import { Popup } from "../core/Popup";
import { Textbox, Textarea } from "../core/Textbox";
import { List } from '../core/List'
import { ApiDataAccess } from "../infrastructure/ApiDataAccess";
import { Configuration } from "../infrastructure/Configuration";


export const PendingTasksView = () => {

    const requestPopup = useRef(null);
    const failPopup = useRef(null);

    const [item, setItem] = useState(null);
    const [estimatorUrl, setEstimator] = useState(null);
    const tasksDataAccess = new ApiDataAccess('/api/tasks/');

    useEffect(() => {
        async function init() {
            let configuration = new Configuration();
            var config = await configuration.getConfigAsync();
            //setConfig(config);

            //console.log(config);

            if (config.settings && config.settings["Gateways:EstimatorUri"]) {
                var url = config.settings["Gateways:EstimatorUri"];
                setEstimator(url)
            }

        };

        init();
    }, []);



    const showPopup = (item) => {
        console.log(item);
        setItem(item);
        requestPopup.current.show();

    }
    const onTest = () => {

    }

    const onCompleteTask = () => {

        tasksDataAccess.post(item.id + "/complete", null)
            .then(data => {
                console.log('data', data);
                if (data === true) {
                    window.location.reload();
                } else {
                    requestPopup.current.close();
                    failPopup.current.show();
                }
            }).catch(error => {
                console.log(error);
                requestPopup.current.close();
                failPopup.current.show();
            });
    }


    return (
        <div>
            <h1>Pending Tasks</h1>
            <List
                controller='api/tasks'
                action='/pending'
                sortColumn='globalOrderId'
                sortDesc={true}
                headers={[
                    { column: 'globalOrderId', title: 'Global Order Id', sortable: true, className: 'col-2 col-sm-2' },
                    { column: 'eventMessage', title: 'Task Description', sortable: false, className: 'col-9 col-sm-9' },
                    { column: 'action', title: 'Action', sortable: true, className: 'col-1 col-sm-1' },
                ]}
                onRenderRow={(column, item, header) => {
                    switch (column) {


                        case 'action':
                            return (
                                <div className="actions">
                                    <span className="icon" onClick={() => showPopup(item)} title="Complete Task">
                                        <AlertCircle className='icon' style={{ "color": "#F47745" }} />
                                    </span>

                                </div>
                            )

                        case 'globalOrderId':
                            var url = estimatorUrl + "offer/summary/" + item.acceptedOfferKey;
                            return (<a href={url} target="_blank" rel="noopener noreferrer" >{item.globalOrderId}</a>);

                        default:
                            return item[column] != null ? <span>{item[column]}</span> : <span>&nbsp;</span>;
                            return <div></div>
                    }
                }}
            />
            <Popup closeOnClickAway={true} ref={requestPopup} closeButton={true}>
                <div className='topSection'>
                    <div className='center'><h4>Mark As Complete?</h4></div>
                </div>
                <div className='contentSection'>
                    <button className='btn btn-primary' onClick={() => requestPopup.current.close()}>Cancel</button>
                    <button className='btn btn-secondary' onClick={() => onCompleteTask()}>Completed</button>
                </div>
            </Popup>
            <Popup closeOnClickAway={true} ref={failPopup}>
                <div className='contentSection'>
                    <h4>Sorry, an error occurred when trying to complete this task. Please try again or contact IT</h4>
                    <AlertCircle className='icon' style={{ "color": "#BB1128", height: "30px" }} />
                </div>
            </Popup>
        </div>
    );
}

